$prim: #ffffff;

body {
  margin: 0;
  font-family: Montserrat, 'Roboto', 'Ubuntu', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //background-image: url(../images/background.avif); //not supported in safari < 16 or opera
  //background-image: url(../images/background/background_landscape.png);
  //background-size: cover;
  //background-color: #30314c;
}

creditcard {
  background-image: url(../images/creditcard.svg);
}

button {
  background-color: #00ff00;
  padding: 10px 20px;
  margin: 10px 0px;
  cursor: pointer;
}

@font-face {
  font-family: 'Righteous';
  src: local('Righteous'), url(../fonts/Righteous-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(../fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.qrcode {
  //filter: drop-shadow(0px 1px 10px #ffffff);
}

:root {
  --toastify-color-error: #FFFFFF;
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-text-color-light: #FFFFFF;
  --toastify-color-dark: #FFF;
}

.Toastify__close-button {
  color: #fff;
  margin-right: 5px;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.thecube {
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);

  .cube {
    position: relative;
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }

  .rotateLoading {
    transform: rotateZ(360deg);
    animation: spin 2.4s infinite linear both;
  }

  .cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $prim;
    -webkit-animation: foldthecube 2.4s infinite linear both;
    animation: foldthecube 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }

  .c2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
    transform: scale(1.1) rotateZ(90deg);
  }

  .c3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
    transform: scale(1.1) rotateZ(180deg);
  }

  .c4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
    transform: scale(1.1) rotateZ(270deg);
  }

  .c2:before {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .c3:before {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .c4:before {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }

  @keyframes foldthecube {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
      transform: perspective(140px) rotateX(-180deg);
      opacity: 0;
    }

    25%,
    75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
      transform: perspective(140px) rotateX(0deg);
      opacity: 1;
    }

    90%,
    100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
      transform: perspective(140px) rotateY(180deg);
      opacity: 0;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  font: normal 15px/28px Arial, Sans-Serif;
  color: transparent;
  background-image: url("../images/account-edit.svg");
  background-size: cover;
  border: 1px solid #ccc;
}

.scan {
  animation: moveDown 1.5s ease-in-out alternate-reverse infinite;
}

.oscillateVertically {
  animation: moveDownVeryLittle 3s ease-in-out alternate-reverse infinite;
}

.rotateIndefinitely {
  transform: rotateZ(360deg);
  animation: spin 2.4s infinite ease-in-out both;
}

@keyframes moveDown {
  from {
    transform: translateY(-2rem);
  }
  to {
    transform: translateY(6rem);
  }
}

@keyframes moveDownVeryLittle {
  from {
    transform: translateY(-0.08rem);
  }
  to {
    transform: translateY(0.08rem);
  }
}

.twoColumnContainer {
  display: flex;
  align-items:flex-start
}

.wfullm {
  width: 50%;
}

.mainLogo {
  margin-top: 1.5rem;
  margin-left: 1.5rem;
}

.invisibleOnMobile {
  display: block;
}

@media only screen and (max-width: 900px) {
  .twoColumnContainer {
    /* it place the items in vertical direction */
    flex-direction: column;
    align-items:center;
  }

  .invisibleOnMobile {
    display: none;
  }

  .box {
    margin: 0 0 1rem;
  }

  .wfullm {
    width: 100%;
  }
  .mainLogo {
    margin-top: 0.5rem;
  }
}

@media (orientation: landscape) {
  .heightSmallerOnPortrait {
    margin-top: 10rem;
    margin-left: 6rem;
  }
  .textBubble {
    background-image: url("../images/background/text_bubble_small.svg");
    margin-right: -26rem;
    padding-bottom: 6rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 1.5rem;
    height: 18rem;
    width: 19rem;
  }
  .bigTextBubble {
    background-image: url('../images/background/text_bubble_big.svg');
    margin-bottom: -4rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 4.5rem;
    padding-top: 1.5rem;
    height: 36rem;
    width: 38rem;
  }
  .rotatingStar {
    margin-right: 10rem;
    margin-top: 5rem;
  }
}

@media (orientation: portrait) {
  .heightSmallerOnPortrait {
    margin-top: 1rem;
  }

  .twoColumnContainer {
    /* it place the items in vertical direction */
    flex-direction: column;
    align-items:center;
  }

  .textBubble {
    background-image: url('../images/background/text_bubble_small_down.svg');
    margin-bottom: -4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 2.5rem;
    padding-top: 1.5rem;
    height: 14rem;
    width: 19rem;
  }

  .bigTextBubble {
    background-image: url('../images/background/text_bubble_small_down.svg');
    margin-bottom: -4rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 2.5rem;
    padding-top: 1.5rem;
    height: 14rem;
    width: 19rem;
  }

  .rotatingStar {
    margin-right: 2rem;
    margin-top: 4rem;
  }
}
